<template>
  <div class="attachment-list">
    <attachment-item
      v-for="(el, index) in attachmentList"
      :key="index"
      class="attachment-list__item"
      :file="el"
      :with-delete-button="withDeleteButton"
      :truncate-item-title-size="truncateItemTitleSize"
      :is-show-image="isShowImage"
      @remove="onRemove"
    />
  </div>
</template>

<script>
import AttachmentItem from './AttachmentItem.vue';

export default {
  name: 'AttachmentList',
  components: { AttachmentItem },
  props: {
    attachmentList: {
      type: Array,
      default: undefined,
    },
    withDeleteButton: {
      type: Boolean,
    },
    truncateItemTitleSize: {
      type: Number,
      default: 40,
    },
    isShowImage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onRemove(fileName) {
      this.$emit('remove', fileName);
    },
  },
};
</script>
<style lang="scss" scoped>
.attachment-list {
  width: 100%;
}
</style>
